import { useEffect, useState, useRef } from 'react'
import { uniqueId }            from 'lodash'
import {
    Dropdown,
    Button,
    Divider,
    Label,
    Form,
    TextArea,
    Segment
} from 'semantic-ui-react'

import { api } from '../../services/api'
import { convertArrayToString, convertStringToArray } from '../../tools/helpers'
import {
    HeadContentContainer,
    CompanyDetailsContainer,
    CompanyManipulationContainer,
    CompanyEditContainer,
    CompanyEditContentTop,
    CompanyEditContentBottom,
    Footer,
    MainContainer,
    PresentationContainer,
} from './style'

import FileInput        from './FileInput'
import SlidesManipulate from './SlidesManipulate'
import SlidesNumbers    from './SlidesNumbers'
import { toast }        from 'react-toastify'



export default function CreateCompany(props){
    const [calculatedHeight, setCalculatedHeight] = useState(null)
    const [principalAnexo, setPrincipalAnexo]     = useState({ principal: true, anexo: false })
    const [companies, setCompanies]               = useState(null)
    const [files, setFiles]                       = useState({ cover: null, backCover: null, footer: null, header: null })
    const [presentation, setPresentation]         = useState(null)
    const [companyData, setCompanyData]           = useState({
        company: null,
        operator: null,
        broker: null,
        new: false,
        slideToRemovePrincipal: null,
        slideToRemoveAnexo: null,
        description: '',
        cover: null,
        footer: null,
        header: null,
        backCover: null,
        preview: {
            principal: null,
            anexo: null
        }
    })

    const dropdownCompany = useRef(null)

    useEffect(() => {
        async function fetchData() {
            const res = await api.get('company/list')

            const formattedData = []

            res.data.map(element => {
                element.dashboard_param && formattedData.push({
                    key: uniqueId(),
                    value: element.dashboard_param,
                    text: element.dashboard_param 
                })

                return element
            })

            setCompanies(formattedData)
        }

        fetchData()
    }, [])

    function toggleSelected(slideNumber) {
        const newPresentation = presentation.map(slide => {
            return slide.number === slideNumber ? { ...slide, selected: !slide.selected } : slide
        })

        setPresentation(newPresentation)
    }

    async function handlePresentation(companyName) {
        if(!companyName){
            return
        }

        const res = await api.post('/company/find', { dashboard_param: companyName })

        res.data.preview.principal.sort((a,b) => (a.number > b.number) ? 1 : ((b.number > a.number) ? -1 : 0))
        res.data.preview.anexo.sort((a,b) => (a.number > b.number) ? 1 : ((b.number > a.number) ? -1 : 0))

        if(!res.data.slides_to_remove.principal){
            res.data.slides_to_remove = {principal: '5, 10 22, 25, 26, 40, 41, 42, 47', anexo: null}
        }

        setCompanyData({
            id             : res.data.company_id,
            company        : res.data.company,
            operator       : res.data.operator,
            broker         : res.data.broker,
            new            : res.data.new,
            slidesToRemove : res.data.slides_to_remove,
            description    : res.data.description,
            cover          : res.data.images.cover,
            footer         : res.data.images.footer,
            backCover      : res.data.images.back_cover,
            header         : res.data.images.header,
            preview        : res.data.preview
        })

        populateSlides(res.data.preview.principal, res.data.slides_to_remove.principal)
    }

    function populateSlides(slides, slidesToBeRemoved) {
        setPresentation(slides.map(e => {
            const {
                imgSrc: image,
                normallyExcluded,
                number
            } = e

            return {
                image,
                normallyExcluded,
                number,
                selected: slidesToBeRemoved && convertStringToArray(slidesToBeRemoved).includes(number)
            }
        }))
    }

    function handlePrincipalAnexo(type) {
        if(type === 'principal'){
            setPrincipalAnexo({ principal: true, anexo: false })

            if(companyData.preview.principal){
                populateSlides(companyData.preview.principal, companyData.slidesToRemove.principal)
            }
        }else{
            setPrincipalAnexo({ principal: false, anexo: true })

            if(companyData.preview.anexo){
                populateSlides(companyData.preview.anexo, companyData.slidesToRemove.anexo)
            }
        }
    }

    function handleUploadFiles(file, type) {
        setFiles({...files, [type]: file})
    }

    async function uploadFile(file, type) {
        const formData = new FormData()

        formData.append('file', file)
        formData.append('companies_id', companyData.id)
        formData.append('type', type)
        
        const createResp = await api.post('/image/store', formData, {
            headers: {
                'Content-Type': 'application/json'
            }
        })

        if(createResp.data.message === 'File already exists.'){
            const updateResp = await api.post('/image/update', formData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            return updateResp
        }

        return createResp
    }

    async function send(){
        let slidesToRemove = []

        presentation.forEach(e => {
            if(e.selected) slidesToRemove.push(e.number)
        })

        if(files.cover){
            const res = await uploadFile(files.cover, 'cover')
            
            if(res.status !== 200){
                toast.error('Ocorreu um erro ao subir a capa para o servidor.')
                return
            }
        }

        if(files.footer){
            const res = await uploadFile(files.footer, 'footer')
            
            if(res.status !== 200){
                toast.error('Ocorreu um erro ao subir a capa para o servidor.')
                return
            }
        }

        if(files.backCover){
            const res = await uploadFile(files.backCover, 'back_cover')
            
            if(res.status !== 200){
                toast.error('Ocorreu um erro ao subir a capa para o servidor.')
                return
            }
        }

        if(files.header){
            const res = await uploadFile(files.header, 'header')
            
            if(res.status !== 200){
                toast.error('Ocorreu um erro ao subir o cabeçalho para o servidor.')
                return
            }
        }

        const createCompanyResponse = await api.post('/company/store', {
            dashboard_param: companyData.company,
            slides_to_remove_principal: principalAnexo.principal ? convertArrayToString(slidesToRemove) : companyData.slideToRemovePrincipal,
            slides_to_remove_anexo: principalAnexo.anexo ? convertArrayToString(slidesToRemove) : companyData.slideToRemoveAnexo,
            description: companyData.description
        })

        if(createCompanyResponse.status === 200){
            toast.success(createCompanyResponse.data)
            dropdownCompany.current.clearValue()
            setCompanyData({
                company: null,
                operator: null,
                broker: null,
                new: false,
                slideToRemovePrincipal: null,
                slideToRemoveAnexo: null,
                description: '',
                cover: null,
                footer: null,
                backCover: null,
                header: null,
                preview: {
                    principal: null,
                    anexo: null
                }
            })
            setPresentation([])
        }
    }

    return (
        <MainContainer>
            <HeadContentContainer>
                <div>
                    <Dropdown
                        placeholder='Selecione a Empresa'
                        fluid
                        search
                        selection
                        options={companies}
                        onChange={(event, data) => { handlePresentation(data.value) }}
                        style={{minWidth: '280px'}}
                        ref={dropdownCompany}
                    />
                </div>
                <div>
                    <Button.Group>
                        <Button active={principalAnexo.principal} onClick={e => handlePrincipalAnexo('principal')}>Principal</Button>
                        <Button active={principalAnexo.anexo} onClick={e => handlePrincipalAnexo('anexo')}>Anexo</Button>
                    </Button.Group>
                </div>
            </HeadContentContainer>
            <Divider/>
            <div>
                <CompanyDetailsContainer>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Segment.Group horizontal size={'small'} compact={true} style={{ margin: 0 }}>
                            {companyData.new && <Label color='green' floating size='tiny'>Nova!</Label>}
                            <Segment style={{ height: '70px', minWidth: '80px', padding: '10px'}}>
                                <Label attached='top' size={'tiny'}>Operadora</Label>
                                <h4 style={{ color: '#393e46', fontWeight: '600' }}>{companyData.operator}</h4>
                            </Segment>
                            <Segment  style={{ height: '70px', minWidth: '80px', padding: '10px'}}>
                                <Label attached='top' size={'tiny'}>Empresa</Label>
                                <h4 style={{ color: '#393e46', fontWeight: '600' }}>{companyData.company}</h4>
                            </Segment>
                            <Segment style={{ height: '70px', minWidth: '80px', padding: '10px'}}>
                                <Label attached='top' size={'tiny'}>Corretora</Label>
                                <h4 style={{ color: '#393e46', fontWeight: '600' }}>{companyData.broker}</h4>
                            </Segment>
                        </Segment.Group>
                    </div>
                </CompanyDetailsContainer>
                <CompanyManipulationContainer>
                    <PresentationContainer>
                        {presentation && presentation.map(e => <SlidesManipulate key={e.number} slide={e} toggleSelected={toggleSelected}/>)}
                    </PresentationContainer>
                    <CompanyEditContainer>
                        <CompanyEditContentTop>
                            <div>
                                <label className='mb-2 font-weight-bold'>Slides Removidos</label>
                                {
                                    presentation && presentation.some(item => item.selected) ? 
                                        <SlidesNumbers presentation={presentation}/> :
                                        null
                                }
                            </div>
                            <div>
                                <label className='mb-2 font-weight-bold'>Comentários</label>
                                <Form>
                                    <TextArea
                                        onChange={(event, data) => setCompanyData({ ...companyData, description: data.value })}
                                        value={companyData.description || ''}
                                    />
                                </Form>
                            </div>
                        </CompanyEditContentTop>
                        <CompanyEditContentBottom>
                            <FileInput type='Capa' image={companyData.cover} handleUploadFile={handleUploadFiles}/>
                            <FileInput type='Contracapa' image={companyData.backCover} handleUploadFile={handleUploadFiles}/>
                            <FileInput type='Rodapé' image={companyData.footer} handleUploadFile={handleUploadFiles}/>
                            <FileInput type='Cabeçalho' image={companyData.header} handleUploadFile={handleUploadFiles}/>
                        </CompanyEditContentBottom>
                    </CompanyEditContainer>
                </CompanyManipulationContainer>
            </div>
            <Footer>
                <Button primary onClick={() => send()} disabled={companyData.company ? false : true}>Enviar</Button>
            </Footer>
        </MainContainer>
    )
}
