import axios from 'axios'

// const baseURL = 'http://localhost:3000'
const baseURL = 'https://suripoint-backend-87769041781.us-east1.run.app'
// const baseURL = 'https://suripoint-backend-hom-sar7gmwfia-ue.a.run.app'
const gcsAutomateURL = 'https://suri-automate-gcs-sar7gmwfia-rj.a.run.app'
// const suridataApiURL = 'http://localhost:8000'
const suridataApiURL = 'https://credentials-api-sar7gmwfia-ue.a.run.app'

const api = axios.create({
    baseURL: baseURL
})

const gcsAutomateApi = axios.create({
    baseURL: gcsAutomateURL
})

const suridataApi = axios.create({
    baseURL: suridataApiURL,
    headers: { 
        'Authorization': localStorage.getItem('token')
    }
})

// eslint-disable-next-line
export { api, baseURL, gcsAutomateApi, suridataApi };