import { useState } from 'react'
import { ToastContainer } from 'react-toastify'

import Navigation   from './components/Navigation/index'
import ReleaseNotes from './components/ReleaseNotes/index'


import 'react-toastify/dist/ReactToastify.css'
import GlobalStyle  from './styles/global'
import { Label } from 'semantic-ui-react'
import { 
    Container, 
    Content, 
    Title, 
    HeaderContent, 
    HeaderLabelsContainer, 
    MainContainer
} from './styles'


function App() {
    const [modalShow, setModalShow] = useState(false)

    const appVersion = 'v2.1.0'
    const features = [
        'Adicionada aba de credenciais (Necessário autentificação)',
        'Adicionada descrição em inglês',
    ]
    const improvements = [
        'Parametrização da capa da Contaget',
        'Alteração do nome para os arquivos da Sciath',
        'Parametrização da capa da BWG',
        'Parametrização da capa da Rrana',
        'Slides removidos por padrào para empresas novas: 5, 10, 20, 37, 38, 39 e 44',
    ]
    const bugsFixes = [
        'Removida a tag de COVID do primeiro slide'
    ]

    if(!localStorage.getItem('showReleaseNotes')){
        setModalShow(true)
        localStorage.setItem('showReleaseNotes', false)
    }


    return (
        <MainContainer>
            <GlobalStyle />
            <ReleaseNotes 
                show={modalShow}
                onHide={() => setModalShow(false)}
                appVersion={appVersion}
                improvements={improvements}
                features={features}
                bugsFixes={bugsFixes}
            />
            <ToastContainer theme="colored"/>
            <Container>
                <Content>
                    <HeaderContent>
                        <HeaderLabelsContainer>
                            <Label as='a' onClick={() => setModalShow(true)}>{appVersion}</Label>
                        </HeaderLabelsContainer>
                        <Title>Suripoint</Title>
                    </HeaderContent>
                    <Navigation/>
                </Content>
            </Container>
        </MainContainer>
    )
}

export default App
